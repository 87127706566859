<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-11 08:27:39
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-12 17:56:53
-->
<template>
  <div class="search">
    <Header @getlist="getlist"></Header>
    <div class="search-content">
      <div
        class="item-list"
        v-for="(item, index) in list"
        :key="index"
        @click="goDetails(item.url,item.id)"
      >
        <div>
          <span>{{ item.title }}</span>
          <!-- <span>：以1027.62万元竞得河南偃师一地块使用权</span> -->
        </div>
        <span>{{ item.createtime }} 来源:{{ item.source }}</span>
        <div>
          <!-- <span>7月28日丨</span>
          <span>建龙微纳</span> -->
          <span>
            {{ item.brief }}
          </span>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/mobile/Header.vue";
import Footer from "../../../components/mobile/Footer.vue";
export default {
  name: "Search",
  data() {
    return {
      searchVal: "",
      list: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.searchVal = this.$route.query.searchVal;
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        url: "/index/search",
        params: {
          keyword: this.searchVal,
        },
      }).then((res) => {
        console.log(res);
        this.list = res.data.list;
        // this.itemList = res.data.list;
        // const search = r;
      });
    },
    getlist(value) {
      this.searchVal = value;
      this.getData();
    },
    goDetails(url,id) {
      console.log(url, "url",id);      //  this.$router.push(url)
      if (url == "anli/detail") {
       this.$router.push(`/m_searchdetail/${id}`)
      }if (url == "caijing/detail") {
       this.$router.push(`/m_hotdetails/${id}`)
      }if (url == "xingwen/detail") {
       this.$router.push(`/m_dynamicdetail/${id}`)
      }if (url == "lilun/detail") {
       this.$router.push(`/m_ruraldetail/${id}`)
      }if (url == "yingxiang/details") {
        this.$router.push(`/m_videodetails/${id}`)
      }if (url == "todayhot/detail") {
       this.$router.push( `/daydetails/${id}`)
      }
      // else{
      //   this.$router.push(`/m_healthylifedetail/${id}`)
      // }
    },
  },
};
</script>

<style lang='less' scoped>
.search {
  .search-content {
    width: 690px;
    margin: 39px auto 69px;
    .item-list {
      padding-bottom: 40px;
      margin-bottom: 29px;
      border-bottom: 1px solid #dcdcdc;
      > div:nth-child(1) {
        font-size: 26px;
        color: #333333;
        > span:nth-child(1) {
          color: #ff0000;
        }
      }
      > span:nth-child(2) {
        display: block;
        margin: 17px 0 22px 0;
        font-size: 20px;
        color: #666666;
      }
      > div:nth-child(3) {
        font-size: 22px;
        color: #666666;
        > span:nth-child(2) {
          color: #ff0000;
        }
      }
    }
  }
}
</style>